import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { H1 } from 'components/typography/H1'
import PageLayout from 'components/layout/pageLayout'
import { H4 } from 'components/typography/H4'
import { P } from 'components/typography/P'
import { Space } from 'components/layout/Space'
import { TimeLine, SrcollLine, TimeLineItem, Time, Event, Where, Dot, PageSectionLight } from './styles'
import PageHeader from 'components/layout/pageHeader'
import { useDistanceScrolled, useElementScrolled } from 'hooks/useScrolled'
import { timeline, TimeLineProps } from './timeline'

function Schedule() {
  const timelineRefs = useRef<(HTMLDivElement | null)[]>([])
  return (
    <PageLayout>
      <PageHeader heading="Schedule" />
      <PageSectionLight>
        <TimeLine>
          <SrcollLine />
          {timeline.map((t: TimeLineProps, i: number) => {
            const ref = useRef<HTMLDivElement>(null)
            timelineRefs.current[i] = ref.current
            const activeScroll = useElementScrolled(ref, 450)
            return (
              <TimeLineItem key={'timeline-' + i} ref={ref}>
                <Dot active={activeScroll} />
                <Time>{t.time}</Time>
                <Event>{t.event}</Event>
                <Where>{t.where}</Where>
              </TimeLineItem>
            )
          })}
        </TimeLine>
      </PageSectionLight>
    </PageLayout>
  )
}

export default Schedule
