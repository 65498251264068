export type TimeLineProps = { 
    time: string
    event: string
    where: string
}

export const timeline = [
    {
        time: '3:30 pm',
        event: 'Guest Arrival',
        where: 'Chapel Gardens',
    },
    {
        time: '3:45 pm',
        event: 'Ceremony',
        where: 'Chapel Gardens',
    },
    {
        time: '5:00 pm',
        event: 'Canapés & Drinks',
        where: 'Barn/Gardens',
    },
    {
        time: '6:00 pm',
        event: 'Reception Dinner',
        where: 'Barn/Gardens',
    },
    {
        time: '8:30 pm',
        event: 'Dance Floor Opens',
        where: 'Barn',
    },
    {
      time: '10:00 pm',
      event: 'Noodles Served',
      where: 'Barn',
    },
    {
      time: '10:45 pm',
      event: 'Night Ends',
      where: 'Barn',
    }
    // {
    //     time: '4:30 pm',
    //     event: 'Guests invited to reception',
    //     where: 'Barn/Gardens',
    // },
    // {
    //     time: '5:00 pm',
    //     event: 'Canopee & Drinks',
    //     where: 'Barn/Gardens',
    // },
    // {
    //     time: '6:00 pm',
    //     event: 'Welcome Speech',
    //     where: 'Barn',
    // },
    // {
    //     time: '6:15 pm',
    //     event: 'Introduce Newly Weds',
    //     where: 'Barn',
    // },
    // {
    //     time: '6:30 pm',
    //     event: 'Entrees',
    //     where: 'Barn',
    // },
    // {
    //     time: '6:40 pm',
    //     event: 'Call for Speeches',
    //     where: 'Barn',
    // },
    // {
    //     time: '7:00 pm',
    //     event: 'Main Course',
    //     where: 'Barn',
    // },
    // {
    //     time: '7:15 pm',
    //     event: 'Further Speeches',
    //     where: 'Barn',
    // },
    // {
    //     time: '7:30 pm',
    //     event: 'Sunset Photos',
    //     where: 'Garndens',
    // },
    // {
    //     time: '7:40 pm',
    //     event: 'Cake Cutting',
    //     where: 'Barn',
    // },
    // {
    //     time: '8:00 pm',
    //     event: 'Final Speeches',
    //     where: 'Barn',
    // },
    // {
    //     time: '8:45 pm',
    //     event: 'Dance Floor Opens',
    //     where: 'Barn',
    // },
    // {
    //     time: '9:15 pm',
    //     event: 'First Dance',
    //     where: 'Barn',
    // },
    // {
    //     time: '10:45 pm',
    //     event: 'Night Ends',
    //     where: 'Barn',
    // },
]