import React, { useState, useEffect } from 'react'
import { StyledCountdown, StyledDate, StyledTime, StyledTimeValues, StyledSimpleTime } from './styles'
import { useBreakpoint } from 'hooks/useBreakpoint'

type CountdownProps = {
  targetDate?: string
  simple?: boolean
  showDate?: false
}

type TimeLeft = {
  [key: string]: string
  days: string
  hours: string
  mins: string
  secs: string
}

const Countdown: React.FC<CountdownProps> = ({
  targetDate = '2025-04-17T16:30:00',
  simple = true,
  showDate = false,
}) => {
  const isMobile = useBreakpoint('down', 'md')

  const calculateTimeLeft = (date: string): TimeLeft => {
    const difference = +new Date(date) - +new Date()
    let timeLeft = { days: '0', hours: '0', mins: '0', secs: '0' }

    if (difference > 0) {
      timeLeft = {
        days: formatNumber(Math.floor(difference / (1000 * 60 * 60 * 24)), 'day'),
        hours: formatNumber(Math.floor((difference / (1000 * 60 * 60)) % 24), 'hours'),
        mins: formatNumber(Math.floor((difference / 1000 / 60) % 60), 'minutes'),
        secs: formatNumber(Math.floor((difference / 1000) % 60), 'seconds'),
      }
    }

    return timeLeft
  }

  const formatNumber = (number: number, value: string) => `0${number}`.slice(-2)

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(targetDate))

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(targetDate))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <StyledCountdown>
      {showDate && <StyledDate>17th April, 2025</StyledDate>}
      {simple ? (
        <StyledTimeValues>
          {Object.keys(timeLeft).map((t) => {
            const k = isMobile ? t.charAt(0) : t
            return <StyledSimpleTime><span>{' ' + timeLeft[t] + ' '}</span><span>{t + ' '}</span></StyledSimpleTime>
          })}
        </StyledTimeValues>
      ) : (
        <>
          <StyledTimeValues>
            {Object.keys(timeLeft).map((t) => (
              <StyledTime numbers>{timeLeft[t]}</StyledTime>
            ))}
          </StyledTimeValues>
          <StyledTimeValues>
            {Object.keys(timeLeft).map((t) => (
              <StyledTime>
                <span>{t}</span>
              </StyledTime>
            ))}
          </StyledTimeValues>
        </>
      )}
    </StyledCountdown>
  )
}

export default Countdown
