import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { rem, hexToRGBa, breakpoint } from 'helpers/functions'
import { BecAndSam } from 'components/display/images/becandsam'
import { ButtonStyles, mapButtonColorVariants } from 'components/inputs/Button/styles'
import { BaseButtonProps } from 'components/inputs/Button/types'
import { getCssVar } from 'theme'
import { NavProps } from './types'

export const StyledLogo = styled(BecAndSam)<{ scrolled: boolean }>`
  align-self: flex-start;
  color: ${getCssVar('colors.earth')};
  display: flex;
  flex-direction: column;
  opacity: 0;
  width: ${rem(200)};
`

export const StyledNav = styled.div<NavProps>`
  background-color: ${hexToRGBa('#f3f2f0', 0.6)};
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: ${rem(30)} ${rem(10)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transition: ${getCssVar('transitions.smooth')};

  > a {
    flex: 1;
    max-width: max-content;
  }

  ${breakpoint('down', 'md')} {
    padding: ${rem(15)}; 

    ${props => !props.scrolled && css`
      padding: ${rem(30)}; 
      `}
  }

  ${props => props.scrolled && css`
    background-color: ${getCssVar('colors.offWhite')};
    padding: ${rem(15)} ${rem(10)};
    `}

    ${props => props.visible === false && css`
      transform: translateY(-100%);
    `}

`

export const StyledNavLink = styled(NavLink)<Partial<BaseButtonProps>>`
  align-items: center;
  border-bottom: ${rem(2)} solid transparent;
  color: ${getCssVar('colors.auburn')};
  display: flex;
  font-family: ${getCssVar('font.headingFont')};
  font-size: ${getCssVar('font.size.md')};
  font-weight: bold;
  line-height: 1;
  margin: 0 ${rem(5)};
  padding: ${rem(8)} ${rem(10)};
  &:hover,
  &.active {
    border-color: ${getCssVar('colors.auburn')};
    color: ${getCssVar('colors.auburn')};
  }

  ${breakpoint('down', 'md')} {
    font-size: ${getCssVar('font.size.sm')};
    padding: ${rem(8)} ${rem(6)};
  }
`

export const StyledLeft = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 200px;
`
export const StyledCenter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`
export const StyledRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 200px;
`