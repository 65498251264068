import { NavLink } from 'react-router-dom'
import { StyledLogo, StyledNav, StyledNavLink, StyledLeft, StyledRight, StyledCenter } from './styles'
import { NavProps } from './types'
import { isloggedIn } from 'helpers/auth'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { useScrollDirection } from 'hooks/useScrolled'

const Navigation = () => {
  const loggedIn = isloggedIn()
  const isMobile = useBreakpoint('down', 'md')

  const { scrollDirection, scrollY } = useScrollDirection()
  
  // Determine visibility based on scroll direction
  const isVisible = scrollDirection === 'up' || scrollY < 10 || scrollDirection === null

  return (
    <StyledNav scrolled={scrollY > 10} visible={isVisible}>
      {!isMobile && (
        <StyledLeft>
          <NavLink to="/">
            <StyledLogo scrolled={scrollY > 10} />
          </NavLink>
        </StyledLeft>
      )}
        <StyledCenter>
          <StyledNavLink to="/" variant="subtle">
            Home
          </StyledNavLink>
          <StyledNavLink to="/venue" variant="subtle">
            Venue
          </StyledNavLink>
          <StyledNavLink to="/schedule" variant="subtle">
            Schedule
          </StyledNavLink>
          <StyledNavLink to="/photos" variant="subtle">
            Photos
          </StyledNavLink>
        </StyledCenter>
      {!isMobile && (
        <StyledRight>
          {loggedIn && (
            <StyledNavLink to="/logout" variant="subtle">
              Logout
            </StyledNavLink>
          )}
        </StyledRight>
      )}
    </StyledNav>
  )
}

export default Navigation
