import { useState } from 'react'

import PageLayout from 'components/layout/pageLayout'
import PageHeader from 'components/layout/pageHeader'
import PageContent from 'components/layout/pageContent'
import UploadPhotos from './upload';
import DropboxGallery from './gallery'

const UploadComponent = () => {
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false)
  return (
    <PageLayout>
      <PageHeader heading="Photo Gallery" />
      <PageContent>
        <UploadPhotos setTriggerRefresh={setTriggerRefresh} />
        <DropboxGallery triggerRefresh={triggerRefresh} setTriggerRefresh={setTriggerRefresh} />
      </PageContent>
    </PageLayout>
  );
};

export default UploadComponent;

