import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getCssVar } from 'theme';
import axios from 'axios';
import { rem, hexToRGBa } from 'helpers/functions';
import PageSection from 'components/layout/pageSection';
import { useBreakpoint } from 'hooks/useBreakpoint';

interface DropboxFile {
  id: string;
  name: string;
  path_display: string;
  preview_url?: string;
  thumbnail_url?: string;
  server_modified: string;
}

interface DropboxGalleryProps {
  triggerRefresh: boolean
  setTriggerRefresh: (val: boolean) => void
}

const DropboxGallery: React.FC<DropboxGalleryProps> = ({ triggerRefresh, setTriggerRefresh }) => {
  const [files, setFiles] = useState<DropboxFile[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<DropboxFile | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [hasMore, setHasMore] = useState(true);
  const [cursor, setCursor] = useState<string | null>(null);
  const [loadingMore, setLoadingMore] = useState(false);
  
  // State for current image index (for navigation)
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  // Touch handling for swipe
  const touchStartX = useRef<number | null>(null);
  const touchEndX = useRef<number | null>(null);
  const minSwipeDistance = 50; // minimum distance in px to trigger swipe
  
  const isMobile = useBreakpoint('down', 'md');
  
  useEffect(() => {
    if (triggerRefresh) {
      fetchPhotos();
    }

    return () => setTriggerRefresh(false);
  }, [triggerRefresh, setTriggerRefresh]);

  const fetchPhotos = async (reset = true) => {
    if (reset) {
      setLoading(true);
      setError(null);
      setFiles([]);
      setCursor(null);
      setHasMore(true);
    } else {
      setLoadingMore(true);
    }

    try {
      // Add pagination parameters
      const limit = 28; // Number of items per page
      
      let url = `${process.env.REACT_APP_API_URL}/photos?limit=${limit}`;
      
      // Add cursor for continued requests
      if (!reset && cursor) {
        url += `&cursor=${encodeURIComponent(cursor)}`;
      }
      
      console.log("Fetching URL:", url); // Debug logging
      
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch photos: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log("API Response:", data); // Debug logging
      
      // Handle response for the backend format (array of files)
      if (Array.isArray(data)) {
        if (reset) {
          setFiles(data);
        } else {
          setFiles(prev => [...prev, ...data]);
        }
        
        // Assume no more data since the array format doesn't support pagination
        setHasMore(false);
        setCursor(null);
      } 
      // Handle response with has_more and cursor (new format)
      else if (data && typeof data.has_more === 'boolean') {
        const newFiles = data.files || [];
        
        if (reset) {
          setFiles(newFiles);
        } else {
          setFiles(prev => [...prev, ...newFiles]);
        }
        
        setHasMore(data.has_more);
        setCursor(data.cursor || null);
      }
      // Legacy format with files property but no pagination
      else if (data && Array.isArray(data.files)) {
        if (reset) {
          setFiles(data.files);
        } else {
          setFiles(prev => [...prev, ...data.files]);
        }
        
        // No cursor means no more data
        setHasMore(false);
      } else {
        console.error('Unexpected response format:', data);
        throw new Error('Invalid response format from server');
      }
      
    } catch (err) {
      console.error('Error fetching photos:', err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const loadMorePhotos = () => {
    if (!loading && !loadingMore && hasMore) {
      fetchPhotos(false);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const handleImageClick = (file: DropboxFile) => {
    const index = files.findIndex(f => f.id === file.id);
    setCurrentImageIndex(index);
    setSelectedImage(file);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setPreviewUrl('');
    setSelectedImage(null);
  };

  const getFullImageUrl = async (file: DropboxFile) => {
    if (file.preview_url) {
      return file.preview_url;
    }
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/preview`, {
        params: { 
          path: file.path_display,
          size: 'w2048h1536' // Request large image
        },
      });

      return response.data?.link;
    } catch (error) {
      console.error('Error in getFullImageUrl:', error);
    }
  };

  // Navigation functions
  const goToNextImage = () => {
    if (currentImageIndex < files.length - 1) {
      const nextIndex = currentImageIndex + 1;
      setCurrentImageIndex(nextIndex);
      setSelectedImage(files[nextIndex]);
      setPreviewUrl(''); // Reset preview URL when changing images
    }
  };

  const goToPrevImage = () => {
    if (currentImageIndex > 0) {
      const prevIndex = currentImageIndex - 1;
      setCurrentImageIndex(prevIndex);
      setSelectedImage(files[prevIndex]);
      setPreviewUrl(''); // Reset preview URL when changing images
    }
  };

  // Touch handlers for swipe navigation
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!touchStartX.current || !touchEndX.current) return;
    
    const distance = touchStartX.current - touchEndX.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      goToNextImage();
    }
    
    if (isRightSwipe) {
      goToPrevImage();
    }
    
    // Reset touch coordinates
    touchStartX.current = null;
    touchEndX.current = null;
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!modalOpen) return;
      
      switch (e.key) {
        case 'ArrowRight':
          goToNextImage();
          break;
        case 'ArrowLeft':
          goToPrevImage();
          break;
        case 'Escape':
          closeModal();
          break;
        default:
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [modalOpen, currentImageIndex, files]);

  useEffect(() => {
    if (modalOpen && selectedImage) {
      const loadImageUrl = async () => {
        try {
          setLoading(true);
          const url = await getFullImageUrl(selectedImage);
          setPreviewUrl(url as string);
        } catch (error) {
          console.error('Failed to load image URL:', error);
          setPreviewUrl('');
        } finally {
          setLoading(false);
        }
      };

      loadImageUrl();
    }
  }, [modalOpen, selectedImage]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <PageSectionLight showDots={!isMobile}>
      <GalleryContainer>
        <GalleryHeader>
          <GalleryTitle>Uploaded photos!</GalleryTitle>
          <RefreshButton onClick={() => fetchPhotos()}>
            Refresh
          </RefreshButton>
        </GalleryHeader>
        
        {error && <ErrorMessage>{error}</ErrorMessage>}

        {loading && files.length === 0 ? (
          <LoadingMessage>Loading your photos...</LoadingMessage>
        ) : files.length === 0 && !loading ? (
          <EmptyMessage>No photos found in this folder</EmptyMessage>
        ) : (
          <>
            <ImagesGrid>
              {files.map((file, index) => (
                <ImageCard 
                  key={file.id} 
                  onClick={() => handleImageClick(file)}
                >
                  <LazyImageThumbnail file={file} />
                  <ImageInfo>
                    <ImageName>{file.name}</ImageName>
                    <ImageDate>{formatDate(file.server_modified)}</ImageDate>
                  </ImageInfo>
                </ImageCard>
              ))}
            </ImagesGrid>
            
            {loadingMore && (
              <LoadingMoreMessage>Loading more photos...</LoadingMoreMessage>
            )}
            
            {hasMore && !loadingMore && (
              <LoadMoreButtonContainer>
                <LoadMoreButton onClick={loadMorePhotos}>
                  Load More Photos
                </LoadMoreButton>
              </LoadMoreButtonContainer>
            )}
            
            {!hasMore && files.length > 0 && (
              <EndMessage>You've reached the end of the gallery</EndMessage>
            )}
          </>
        )}

        {/* Image Preview Modal */}
        {modalOpen && selectedImage && (
          <Modal 
            onClick={closeModal}
            isMobile={isMobile}
          >
            <ModalContent 
              onClick={(e) => e.stopPropagation()}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              isMobile={isMobile}
            >
              <CloseButton onClick={closeModal}>×</CloseButton>
              
              {/* Navigation buttons on desktop */}
              {!isMobile && (
                <>
                  {currentImageIndex > 0 && (
                    <NavButton left onClick={goToPrevImage}>
                      &#10094;
                    </NavButton>
                  )}
                  
                  {currentImageIndex < files.length - 1 && (
                    <NavButton right onClick={goToNextImage}>
                      &#10095;
                    </NavButton>
                  )}
                </>
              )}
              
              {/* Touch indicators for mobile */}
              {isMobile && (
                <>
                  {currentImageIndex > 0 && <SwipeIndicator left />}
                  {currentImageIndex < files.length - 1 && <SwipeIndicator right />}
                </>
              )}
              
              {previewUrl ? (
                <ModalImage src={previewUrl} alt={selectedImage.name} />
              ) : (
                <ModalLoading />
              )}
              
              <ModalCaption isMobile={isMobile}>
                <h3>{selectedImage.name}</h3>
                <p>{formatDate(selectedImage.server_modified)}</p>
                <ImageCounter>
                  {currentImageIndex + 1} of {files.length}
                </ImageCounter>
              </ModalCaption>
            </ModalContent>
          </Modal>
        )}
      </GalleryContainer>
    </PageSectionLight>
  );
};

// LazyImageThumbnail component for better thumbnail loading
const LazyImageThumbnail: React.FC<{ file: DropboxFile }> = ({ file }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { rootMargin: '100px' } // Load images that are 100px from viewport
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    setLoaded(false);
  }, [file.thumbnail_url])

  return (
    <ImageThumbnailContainer ref={imgRef}>
      {isVisible && (
        <>
          {!loaded && <ThumbnailPlaceholder />}
          {file.thumbnail_url && (
            <HiddenImage 
              src={file.thumbnail_url} 
              onLoad={() => setLoaded(true)}
              onError={() => setLoaded(true)} // Also handle error cases
            />
          )}
          <ImageThumbnail 
            url={file.thumbnail_url || ''} 
            loaded={loaded}
            style={{ display: file.thumbnail_url ? 'block' : 'none' }}
          />
        </>
      )}
      {!isVisible && <ThumbnailPlaceholder />}
    </ImageThumbnailContainer>
  );
};

// Fix missing useRef import
import { useRef } from 'react';

export default DropboxGallery;

// Styled Components
export const PageSectionLight = styled(PageSection)`
  background: ${hexToRGBa('#f3f2f0', 0.8)} !important;
  margin-top: ${rem(100)};
`;

const GalleryContainer = styled.div`
`;

const HiddenImage = styled.img`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
`;

const GalleryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(15)};
`;

const GalleryTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: ${getCssVar('body.headingColor')};
  margin: 0;
`;

const RefreshButton = styled.button`
  background-color: transparent;
  color: ${getCssVar('colors.auburn')};
  border: 1px solid ${getCssVar('colors.auburn')};
  border-radius: 4px;
  padding: ${rem(8)} ${rem(16)};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(0, 97, 254, 0.05);
  }
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 15px;
`;

const ImageCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ImageThumbnailContainer = styled.div`
  position: relative;
  height: 150px;
  background-color: #f5f5f5;
`;

const ThumbnailPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #ddd;
    border-top-color: ${getCssVar('colors.auburn')};
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ImageThumbnail = styled.div<{ url: string; loaded: boolean }>`
  height: 150px;
  background-image: ${props => props.url ? `url(${props.url})` : 'none'};
  background-size: cover;
  background-position: center;
  opacity: ${props => props.loaded ? 1 : 0};
  transition: opacity 0.3s ease;
`;

const ImageInfo = styled.div`
  padding: 10px;
`;

const ImageName = styled.p`
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImageDate = styled.p`
  font-size: 12px;
  color: #999;
  margin: 5px 0 0 0;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
`;

const LoadingMoreMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
`;

const ErrorMessage = styled.div`
  background-color: #ffe8e8;
  color: #d93025;
  padding: 12px 15px;
  border-radius: 4px;
  margin-bottom: 15px;
`;

const EmptyMessage = styled.div`
  text-align: center;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  color: #666;
`;

const EndMessage = styled.div`
  text-align: center;
  padding: 15px;
  color: #999;
  font-size: 14px;
  margin-top: 15px;
`;

const LoadMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const LoadMoreButton = styled.button`
  background-color: ${getCssVar('colors.auburn')};
  color: white;
  border: none;
  border-radius: 4px;
  padding: ${rem(10)} ${rem(20)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${getCssVar('colors.auburn')};
    opacity: 0.9;
  }
`;

// Updated Modal with mobile fullscreen support
const Modal = styled.div<{ isMobile: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: ${props => props.isMobile ? '0' : '20px'};
`;

const ModalContent = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: ${props => props.isMobile ? '100%' : 'auto'};
  height: ${props => props.isMobile ? '100%' : 'auto'};
  max-width: ${props => props.isMobile ? '100vw' : '90vw'};
  max-height: ${props => props.isMobile ? '100vh' : '90vh'};
  background-color: ${props => props.isMobile ? 'black' : 'white'};
  border-radius: ${props => props.isMobile ? '0' : '8px'};
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

const ModalImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  margin: auto;
  flex: 1;
`;

const ModalLoading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #666;
  
  &:after {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #ddd;
    border-top-color: ${getCssVar('colors.auburn')};
    animation: spin 1s linear infinite;
  }
`;

const ModalCaption = styled.div<{ isMobile: boolean }>`
  padding: 15px;
  background-color: ${props => props.isMobile ? 'rgba(0, 0, 0, 0.7)' : 'white'};
  color: ${props => props.isMobile ? 'white' : 'inherit'};
  
  h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
  }
  
  p {
    margin: 0;
    color: ${props => props.isMobile ? '#ccc' : '#666'};
    font-size: 14px;
  }
`;

const ImageCounter = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #999;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

// Navigation buttons for desktop
const NavButton = styled.button<{ left?: boolean; right?: boolean }>`
  position: absolute;
  top: 50%;
  ${props => props.left ? 'left: 10px;' : ''}
  ${props => props.right ? 'right: 10px;' : ''}
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

// Swipe indicators for mobile
const SwipeIndicator = styled.div<{ left?: boolean; right?: boolean }>`
  position: absolute;
  top: 50%;
  ${props => props.left ? 'left: 10px;' : ''}
  ${props => props.right ? 'right: 10px;' : ''}
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 5;
  
  &:after {
    content: ${props => props.left ? '"←"' : props.right ? '"→"' : '""'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
  }
`;