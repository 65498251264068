import { Button } from 'components/inputs/Button';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { getCssVar } from 'theme';

interface UploadComponentProps {
  maxFileSize?: number; // in bytes, default 10MB
  allowedFileTypes?: string[]; // e.g. ['image/jpeg', 'image/png']
  uploadEndpoint?: string; // default '/api/upload'
  setTriggerRefresh: (val: boolean) => void
}

const UploadPhotos: React.FC<UploadComponentProps> = ({
    maxFileSize = 10 * 1024 * 1024, // 10MB
    allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'],
    uploadEndpoint = `${process.env.REACT_APP_API_URL}/upload`,
    setTriggerRefresh,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    processFiles(Array.from(event.target.files));
  };

  const processFiles = (selectedFiles: File[]) => {
    const invalidFiles: string[] = [];
    const validFiles = selectedFiles.filter(file => {
      // Check file type
      if (!allowedFileTypes.includes(file.type)) {
        invalidFiles.push(`"${file.name}" (unsupported file type)`);
        return false;
      }
      
      // Check file size
      if (file.size > maxFileSize) {
        invalidFiles.push(`"${file.name}" (exceeds ${maxFileSize / (1024 * 1024)}MB limit)`);
        return false;
      }
      
      return true;
    });
    
    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    
    if (invalidFiles.length > 0) {
      setErrorMessage(`Cannot upload: ${invalidFiles.join(', ')}`);
    } else {
      setErrorMessage('');
    }
    
    setUploadStatus('idle');
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      processFiles(Array.from(e.dataTransfer.files));
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setErrorMessage('Please select files to upload');
      return;
    }
    
    setUploading(true);
    setUploadProgress(0);
    setErrorMessage('');
    
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });
    
    try {
      const xhr = new XMLHttpRequest();
      
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });
      
      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          setUploadStatus('success');
          setFiles([]);
        } else {
          setUploadStatus('error');
          setErrorMessage(`Upload failed: ${xhr.statusText}`);
        }
        setUploading(false);
      });
      
      xhr.addEventListener('error', () => {
        setUploadStatus('error');
        setErrorMessage('Upload failed due to network error');
        setUploading(false);
      });
      
      xhr.open('POST', uploadEndpoint);
      xhr.send(formData);
    } catch (error) {
      setUploadStatus('error');
      setErrorMessage(`Upload failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setUploading(false);
    }
    setTimeout(() => setTriggerRefresh(true), 4000)
  };

  // Format file size to human-readable format
  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return bytes + ' bytes';
    else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    else return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  };

  return (
    <Container>
    <DropZone 
      isDragging={isDragging}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <FileInput 
        ref={fileInputRef}
        type="file" 
        multiple 
        accept={allowedFileTypes.join(',')} 
        onChange={handleFileChange}
        disabled={uploading}
      />
      
      <DropzoneContent>
        <UploadIcon>
          <svg width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
          </svg>
        </UploadIcon>
        <UploadText>Drag & drop files here</UploadText>
        <UploadText>or</UploadText>
        <Button 
          onClick={handleBrowseClick}
          disabled={uploading}
        >
          Browse Files
        </Button>
        <FileLimits>
          Allowed: {allowedFileTypes.map(type => type.split('/')[1]).join(', ')} • Max size: {formatFileSize(maxFileSize)}
        </FileLimits>
      </DropzoneContent>
    </DropZone>

    {files.length > 0 && (
      <SelectedFiles>
        <FileListHeader>Files to Upload ({files.length})</FileListHeader>
        <FileList>
          {files.map((file, index) => (
            <FileItem key={index}>
              <FileInfo>
                <FileName>{file.name}</FileName>
                <FileSize>{formatFileSize(file.size)}</FileSize>
              </FileInfo>
              <RemoveFileButton 
                onClick={() => handleRemoveFile(index)}
                disabled={uploading}
              >
                ✕
              </RemoveFileButton>
            </FileItem>
          ))}
        </FileList>
      </SelectedFiles>
    )}
    
    {errorMessage && (
      <ErrorMessage>
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        {errorMessage}
      </ErrorMessage>
    )}
    
    {uploading && (
      <ProgressContainer>
        <ProgressLabel>
          <span>Uploading...</span>
          <span>{uploadProgress}%</span>
        </ProgressLabel>
        <ProgressBar>
          <ProgressFill progress={uploadProgress} />
        </ProgressBar>
      </ProgressContainer>
    )}
    
    {uploadStatus === 'success' && (
      <SuccessMessage>
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        Files uploaded successfully!
      </SuccessMessage>
    )}
    
    <ButtonContainer>
      <Button 
        onClick={handleUpload} 
        disabled={uploading || files.length === 0}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
    </ButtonContainer>
  </Container>
  )
}

export default UploadPhotos


// Styled Components
const Container = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
`;

const DropZone = styled.div<{ isDragging: boolean }>`
  border: 2px dashed ${props => props.isDragging ? '#0061fe' : '#ccc'};
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  background-color: ${props => props.isDragging ? 'rgba(0, 97, 254, 0.05)' : '#f9f9f9'};
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 20px;
`;

const FileInput = styled.input`
  display: none;
`;

const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadIcon = styled.div`
  color: ${getCssVar('colors.auburn')};
  margin-bottom: 15px;
`;

const UploadText = styled.p`
  margin: 5px 0;
  color: #666;
  font-size: 16px;
`;


const FileLimits = styled.p`
  font-size: 12px;
  color: #999;
  margin-top: 10px;
`;

const SelectedFiles = styled.div`
  margin: 20px 0;
`;

const FileListHeader = styled.h3`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
`;

const FileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
`;

const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  background-color: white;

  &:last-child {
    border-bottom: none;
  }
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FileName = styled.span`
  font-weight: 500;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;

  @media (max-width: 600px) {
    max-width: 200px;
  }
`;

const FileSize = styled.span`
  font-size: 12px;
  color: #888;
`;

const RemoveFileButton = styled.button`
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: #f1f1f1;
    color: #666;
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const ProgressContainer = styled.div`
  margin: 20px 0;
`;

const ProgressLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
`;

const ProgressBar = styled.div`
  height: 8px;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressFill = styled.div<{ progress: number }>`
  height: 100%;
  background-color: ${getCssVar('colors.auburn')};
  border-radius: 4px;
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 15px;
  margin: 15px 0;
  background-color: #ffe8e8;
  color: #d93025;
  border-radius: 4px;
  font-size: 14px;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 15px;
  margin: 15px 0;
  background-color: #e7f5ea;
  color: #188038;
  border-radius: 4px;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
