import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from 'pages/home'
import RSVP from 'pages/rsvp'
import Schedule from 'pages/schedule'
import NoMatch from 'pages/nomatch'
import Venue from 'pages/venue'
import OTP from 'pages/otp'
import ProtectedRoute from 'protectedRoute'
import Login from 'pages/login'
import PhotoUpload from 'pages/photos'
import Logout from 'components/auth/logout'

function Routing() {
  return (
    <Routes>
      <Route path={'/'} index element={<Home />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/venue'} element={<Venue />} />
      <Route path={'/rsvp'} element={<ProtectedRoute component={RSVP} />} />
      <Route path={'/schedule'} element={<Schedule />} />
      <Route path={'/travel'} element={<ProtectedRoute component={RSVP} />} />
      <Route path={'/otp'} element={<OTP />} />
      <Route path="*" element={<NoMatch />} />
      <Route path={'/photos'} element={<PhotoUpload />} />
      <Route path="/logout" element={<ProtectedRoute component={Logout} />} />
    </Routes>
  )
}

export default Routing
